import { isNavActive } from "../../utils/utils";
// import { getValue } from "../../utils/sessionManager";

export const CLIENT_NAV = [
  {
    key: "client_dashboard",
    path: "/client-dashboard",
    value: "DashBoard",
    isActive: true,
    isIcon: false,
    isSubscriptionModal: false,
    is_client: false,
    faIcon: "fa fa-th-large"
  },
  {
    key: "Goals",
    path: "/goals",
    value: "Goals",
    isSubscriptionModal: false,
    is_client: false,
    faIcon: "fa fa-dot-circle-o"
  },
  {
    key: "risk_profiling",
    path: "/risk-profiling/Details",
    value: "Risk Profiling",
    isActive: true,
    isIcon: false,
    isSubscriptionModal: false,
    is_client: false,
    faIcon: "fa fa-exclamation-triangle"
  },
  {
    key: "financial_health",
    path: "/financial-health/Brief",
    value: "Financial Health",
    isActive: true,
    isIcon: false,
    isSubscriptionModal: false,
    is_client: false,
    faIcon: "fa fa-medkit"
  },
  {
    key: "holding",
    path: "/holding",
    value: "Holding",
    isIcon: false,
    isSubscriptionModal: false,
    is_client: false,
    faIcon: "fa fa-handshake-o"
  },
  {
    key: "investment",
    path: "/investment",
    value: "Investment",
    isIcon: false,
    isSubscriptionModal: true,
    faIcon: "fa fa-money",
    isSubscriptionActive: () => isNavActive("investment_nav"),
    is_client: false,
  },
  {
    key: "assetMapping",
    path: "/AssetMapping",
    value: "Asset Mapping",
    isIcon: false,
    isSubscriptionModal: true,
    isSubscriptionActive: () => isNavActive("assets_mapping_nav"),
    is_client: false,
    faIcon: "fa fa-th-list"
  },
  {
    key: "dreams",
    path: "/dreams",
    value: "Dreams",
    // isActive: false,
    isIcon: false,
    isSubscriptionModal: true,
    isSubscriptionActive: () => isNavActive("dreams_nav"),
    is_client: false,
  },
  {
    key: "help",
    path: "/help",
    // value: 'help',
    isIcon: true,
    icon: "fa fa-question-circle fa-lg",
    hoverText: "Help & Support",
    isActive: true,
    isSubscriptionModal: false,
    is_client: false,
  },
  // {
  //   key: "cart",
  //   path: "/cart",
  //   // value: 'Cart',
  //   isIcon: true,
  //   icon: "fa fa-shopping-cart",
  //   hoverText: "Cart",
  //   isActive: true,
  //   isSubscriptionModal: false,
  // },
  {
    key: "order_management",
    path: "/order-management",
    //value: 'Order Management',
    isIcon: true,
    icon: "fa fa-tasks fa-lg",
    hoverText: "Transaction History",
    isActive: true,
    isSubscriptionModal: false,
    is_client: false,
  },
  {
    key: "notification",
    path: "/notification",
    //value: 'Order Management',
    isIcon: true,
    icon: "fa fa-bell fa-lg",
    hoverText: "Notifications",
    isActive: true,
    isSubscriptionModal: false,
    is_client: false,
  },
];

export const SUB_ADVISOR_NAV = [
  {
    key: "my_clients",
    path: "/advisor/my-clients",
    value: "My Clients",
    is_client: false,
    isActive: true,
    isIcon: false,
    isSubscriptionModal: false,
  },
  // {
  //   key: "fund_analytics",
  //   path: "/advisor/fund-analytics",
  //   value: "Fund Analytics",
  //   is_client: false,
  //   isActive: true,
  //   isIcon: false,
  //   isSubscriptionModal: false,
  // },
  {
    key: "Portfolio_Analytics",
    path: "/advisor/Portfolio-Analytics",
    value: "Portfolio Analytics",
    is_client: false,
    isActive: true,
    isIcon: false,
    isSubscriptionModal: false,
  },
  {
    key: "business_analytics",
    path: "/advisor/business-analytics",
    value: "Business Analytics",
    is_client: false,
    isIcon: false,
    isSubscriptionModal: false,
  },

  {
    key: "onboarding",
    path: "/advisor/onboarding/new",
    value: "OnBoarding",
    isIcon: false,
    is_client: false,
    isActive: true,
    isSubscriptionModal: false,
  },
  {
    key: "help",
    path: "/advisor/help",
    isIcon: true,
    is_client: false,
    icon: "fa fa-question-circle",
    hoverText: "Help & Support",
    isActive: true,
    isSubscriptionModal: false,
  },
  // {
  //   key: "notification",
  //   path: "/advisor/notification",
  //   isIcon: true,
  //   is_client: false,
  //   icon: "fa fa-bell",
  //   hoverText: "Notifications",
  //   isActive: true,
  //   isSubscriptionModal: false,
  // },
  // advisor to client
  // {
  //   key: "advisor_dashboard_1",
  //   path: "/advisor/my-clients",
  //   value: "My DashBoard",
  //   is_client: true,
  //   isActive: true,
  //   isIcon: false,
  // },
  // {
  //   key: "client_dashboard",
  //   path: "/client-dashboard",
  //   value: "Client DashBoard",
  //   is_client: true,
  //   isActive: true,
  //   isIcon: false,
  // },
  // {
  //   key: "transaction",
  //   path: "/transaction",
  //   value: "Transaction",
  //   is_client: true,
  //   isActive: true,
  //   isIcon: false,
  // },

  // {
  //   key: "risk_profiling",
  //   path: "/risk-profiling/Details",
  //   value: "Risk Profiling",
  //   is_client: true,
  //   isActive: true,
  //   isIcon: false,
  // },
  // {
  //   key: "financial_health",
  //   path: "/financial-health/Brief",
  //   value: "Financial Health",
  //   is_client: true,
  //   isActive: true,
  //   isIcon: false,
  // },

  // {
  //   key: "holding",
  //   path: "/holding",
  //   value: "Holding",
  //   is_client: true,
  //   isActive: true,
  //   isIcon: false,
  // },
  // {
  //   key: "AssetMapping",
  //   path: "/AssetMapping",
  //   value: "Asset Mapping",
  //   is_client: true,
  //   isActive: true,
  //   isIcon: false,
  // },
];

export const SUBADVISOR_CLIENT_LOGIN_NAV = [
  {
    key: "advisor_dashboard_1",
    path: "/advisor/my-clients",
    value: "My DashBoard",
    is_client: true,
    isActive: true,
    isIcon: false,
    isSubscriptionModal: false,
  },
  {
    key: "client_dashboard",
    path: "/client-dashboard",
    value: "Client DashBoard",
    is_client: true,
    isActive: true,
    isIcon: false,
    isSubscriptionModal: false,
  },
  {
    key: "transaction",
    path: "/transaction",
    value: "Transaction",
    is_client: true,
    isActive: true,
    isIcon: false,
    isSubscriptionModal: false,
  },

  {
    key: "risk_profiling",
    path: "/risk-profiling/Details",
    value: "Risk Profiling",
    is_client: true,
    isActive: true,
    isIcon: false,
    isSubscriptionModal: false,
  },
  {
    key: "financial_health",
    path: "/financial-health/Brief",
    value: "Financial Health",
    is_client: true,
    isActive: true,
    isIcon: false,
    isSubscriptionModal: false,
  },

  {
    key: "holding",
    path: "/holding",
    value: "Holding",
    is_client: true,
    isActive: true,
    isIcon: false,
    isSubscriptionModal: false,
  },
  {
    key: "AssetMapping",
    path: "/AssetMapping",
    value: "Asset Mapping",
    is_client: true,
    isActive: true,
    isIcon: false,
    isSubscriptionModal: false,
  },
  {
    key: "cart",
    path: "/cart",
    isIcon: true,
    is_client: true,
    icon: "fa fa-shopping-cart",
    hoverText: "Cart",
    isActive: true,
    isSubscriptionModal: false,
  },
  {
    key: "help",
    path: "/help",
    // value: 'help',
    isIcon: true,
    icon: "fa fa-question-circle",
    hoverText: "Help & Support",
    isActive: true,
    isSubscriptionModal: false,
    is_client: false,
  },
  {
    key: "order_management",
    path: "/order-management",
    //value: 'Order Management',
    isIcon: true,
    icon: "fa fa-tasks",
    hoverText: "Transaction History",
    isActive: true,
    isSubscriptionModal: false,
    is_client: false,
  },
  {
    key: "notification",
    path: "/notification",
    //value: 'Order Management',
    isIcon: true,
    icon: "fa fa-bell",
    hoverText: "Notifications",
    isActive: true,
    isSubscriptionModal: false,
    is_client: false,
  },
];

export const ADVISOR_NAV_1 = [
  {
    key: "cart",
    path: "/cart",
    isIcon: true,
    is_client: true,
    icon: "fa fa-shopping-cart",
    hoverText: "Cart",
    isActive: true,
    isSubscriptionModal: false,
  },
];

export const ADMIN_NAV = [
  {
    key: "admin_dashboard",
    path: "/admin-dashboard",
    value: "DashBoard",
    isActive: true,
    isIcon: false,
    isClient: false,
    isSubscriptionModal: false,
  },
  {
    key: "rta",
    path: "/rta",
    value: "Migration",
    isActive: true,
    isIcon: false,
    isClient: false,
    isSubscriptionModal: false,
  },
    {
    key: "fund_analytics",
    path: "/fund-analytics",
    value: "Fund Analytics",
    is_client: false,
    isActive: true,
    isIcon: false,
    isSubscriptionModal: false,
  },
  {
    key: "advisory process",
    path: "/advisory_process",
    value: "Advisory Process",
    isActive: true,
    isIcon: false,
    isClient: false,
    isSubscriptionModal: false,
  },
  {
    key: "Marketing",
    path: "/marketing",
    value: "Marketing",
    isActive: true,
    isIcon: false,
    isClient: false,
    isSubscriptionModal: false,
  },

  {
    key: "client_mapping",
    path: "/client-mapping",
    value: "Client Stats",
    isActive: true,
    isIcon: false,
    isClient: false,
    isSubscriptionModal: false,
  },
  {
    key: "help",
    path: "/help",
    // value: 'help',
    isIcon: true,
    icon: "fa fa-question-circle fa-lg",
    hoverText: "Help & Support",
    isActive: true,
    isClient: false,
    isSubscriptionModal: false,
  },
  {
    key: "notification",
    path: "/admin-dashboard",
    isIcon: true,
    icon: "fa fa-bell fa-lg",
    hoverText: "Notifications",
    isActive: true,
    isClient: false,
    isSubscriptionModal: false,
  },
];
