import React, { Component, Fragment } from "react";
import config from "../../config";
import { withRouter } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { debounce } from "lodash";
import Toastr from "toastr";

//common
import TextField from "../../components/TextField/TextField";
import Button from "../../components/Button/Button";
//image
//import blog_img_2 from "../../assets/new_image_ui/blog_img_2.jpg";
//actions
import { userSignUp, getAuthToken } from "../../actions/login";
import { singleFieldValidation, allFieldsValidation, waitTime } from "../../utils/validation";
import { sendEmailNotificationSubadvisorSelfSignUp } from "../../actions/common";
import { setValue } from "../../utils/sessionManager";

const CreateUserSchema = {
  first_name: "required",
  email: "required|email",
  mobile: ["required", "numeric", "digits:10"],
  password: "required",
  // company: "required",
  code: "required",
};

class AdvisorSignup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      advisor_type: "MFD",
      signUp: false,
      isButtonClicked: false,
      formFields: {
        first_name: "",
        email: "",
        mobile: "",
        company: "",
        password: "",
        code: "ARN-",
      },
      formErrors: {},
    };
  }

  resetAll = () => {
    const { formFields } = this.state;
    formFields.first_name = "";
    formFields.email = "";
    formFields.mobile = "";
    formFields.password = "";
    formFields.company = "";
    formFields.code = "";
    this.setState({ formFields }, () => {
      this.props.onHide();
      this.props.history.push("/Login-Advisor");
    });
  };

  sendEmailNotification = (userId) => {
    const { formFields } = this.state;

    const emailNotificationData = {
      action_type: "sub_advisor_self_signup",
      name: `${formFields["first_name"]}`,
      email: formFields["email"],
      user_id: userId.toString(),
      role: "SUBADVISOR",
      password: formFields["password"],
    };
    sendEmailNotificationSubadvisorSelfSignUp(emailNotificationData);
  };

  handleSubmit = async () => {
    const { formFields } = this.state;
    const { isValid, errors } = allFieldsValidation(CreateUserSchema, formFields);
    if (isValid) {
      this.setState({ formErrors: {}, isButtonClicked: true });
      let values = {
        name: formFields.first_name,
        email: formFields.email,
        mobile: formFields.mobile,
        password: formFields.password,
        company: formFields.company,
        code: formFields.code,
      };
      setValue("advisor_phonenumber", formFields.mobile);
      try {
        let authRes = "";
        if (config.node_env === "production" || config.node_env === "staging") {
          authRes = await getAuthToken();
        }
        const res = await userSignUp(values, authRes);

        if (res.data.status === "success") {
          let resData = res.data.data[0];
          Toastr.success(resData.message, "success");
          this.sendEmailNotification(resData.user_id);
          setValue("advisor_login_id", resData.user_id);
          this.setState(
            {
              signUp: true,
              isButtonClicked: false,
            },
            this.resetAll()
          );
        } else {
          Toastr.error(res.data.data[0].message, "Failed");
          this.setState({ isButtonClicked: false });
        }
      } catch (err) {
        console.error("handleSubmit, Server err: ", err.response);
        const data = err.response.data;
        this.setState({ server_error: data.error, isButtonClicked: false });
      }
      // this.resetAll();
    } else {
      this.setState({ formErrors: errors, isButtonClicked: false });
    }
  };

  handleInputChange = (event) => {
    const { name, value } = event.target;
    const { formFields } = this.state;
    if (name !== "email") {
      formFields[name] = value.charAt(0).toUpperCase() + value.slice(1);
    } else if (name === "code") {
      formFields[name] = "ARN -" + value;
    } else {
      formFields[name] = value;
    }

    this.setState({ formFields });
    this.debounceSingleFieldValidation({ name, value });
  };

  debounceSingleFieldValidation = debounce(({ name, value }) => {
    const { formErrors } = this.state;
    const { isValid, errors } = singleFieldValidation(CreateUserSchema, { key: name, value });
    if (!isValid) {
      this.setState({ formErrors: { ...formErrors, [name]: errors[name] } });
    } else {
      this.setState({ formErrors: { ...formErrors, [name]: null } });
    }
  }, waitTime);

  render() {
    const { formFields, formErrors, isButtonClicked } = this.state;
    const { first_name, email, mobile, company, code, password } = formFields;
    // const h = window.innerHeight;
    return (
      <Fragment>
        <Modal show={this.props.show} onHide={this.props.onHide} size="md">
          <Modal.Header closeButton className="bg_card_clr">
            <Modal.Title className="col-md-11 text-center">Advisor SignUp</Modal.Title>
          </Modal.Header>
          <Modal.Body className="al_modal_body">
            <Fragment>
              <div className="container">
                {/* {signUp === true && (
          <Fragment>
            <div className="row justify-content-center">
              <div className="col-md-11 col-sm-12">
                <div className="text-center">
                  <p className="h6">
                    Congratulations! We have emailed you the login credentials. Please check and
                    activate your account
                  </p>
                  <p className="pt-3 pb-3">
                    Already have an Account ?
                    <Link to="/login">
                      <span> Login Now</span>
                    </Link>
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-sm-12">
              <img src={blog_img_2} alt="img" className="card-radius" width={""} />
              </div>
            </div>
            </Fragment>
          )} */}
                {/* {signUp === false && ( */}
                <Fragment>
                  <div className="row justify-content-center pt-2">
                    <div className="col-md-10 ">
                      <p className="h4 text-dark">Let’s get started! </p>
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-md-12">
                      <div className="row justify-content-center pt-1">
                        <div className="col-md-10">
                          <TextField
                            error={formErrors["first_name"]}
                            onChange={this.handleInputChange}
                            value={first_name}
                            name="first_name"
                            placeholder="Your Full Name"
                            customClass="custom_input_2"
                          />
                        </div>
                      </div>
                      <div className="row justify-content-center mt-1">
                        <div className="col-md-10">
                          <TextField
                            error={formErrors["mobile"]}
                            onChange={this.handleInputChange}
                            value={mobile}
                            name="mobile"
                            placeholder="Mobile Number"
                            customClass="custom_input_2"
                          />
                        </div>
                      </div>
                      <div className="row justify-content-center mt-1">
                        <div className="col-md-10">
                          <TextField
                            error={formErrors["email"]}
                            onChange={this.handleInputChange}
                            value={email}
                            name="email"
                            placeholder="Email id"
                            customClass="custom_input_2"
                          />
                        </div>
                      </div>

                      <div className="row justify-content-center mt-1">
                        <div className="col-md-10">
                          <TextField
                            error={formErrors["code"]}
                            value={code}
                            name="code"
                            customClass="custom_input_2"
                            onChange={this.handleInputChange}
                          />
                        </div>
                      </div>
                      <div className="row justify-content-center mt-1">
                        <div className="col-md-10">
                          <TextField
                            error={formErrors["password"]}
                            onChange={this.handleInputChange}
                            value={password}
                            name="password"
                            type="password"
                            customClass="custom_input_2"
                            placeholder="Password"
                          />
                        </div>
                      </div>
                      <div className="row justify-content-center mt-1">
                        <div className="col-md-10">
                          <TextField
                            type="text"
                            onChange={this.handleInputChange}
                            value={company}
                            name="company"
                            customClass="custom_input_2"
                            placeholder="Company Name"
                          />
                        </div>
                      </div>
                      {/* <div className="row justify-content-center my-2">
                    <div className="col-12 col-md-10">
                      <p className="text_font_13">
                        Please note that by submitting the above mentioned details, you are
                        authorizing us to call/SMS/Whatsapp you even though you maybe be registered
                        under DND. We shall call/SMS/Whatsapp you for a period of 12 weeks.{" "}
                      </p>
                    </div>
                  </div> */}
                      <div className="row my-5 justify-content-center">
                        <Button
                          type="button"
                          size="md"
                          text="Sign up For Free"
                          customClass={`al_button_new`}
                          onClick={() => this.handleSubmit()}
                          isLoading={isButtonClicked}
                        />
                      </div>
                    </div>
                  </div>
                </Fragment>
                {/* )} */}
              </div>
            </Fragment>
          </Modal.Body>
        </Modal>
      </Fragment>
    );
  }
}

export default withRouter(AdvisorSignup);
